<template>
<div>
  <el-radio-group v-model="activeName" size="medium" style="margin-bottom:20px">
    <el-radio-button label="1">采集配置项</el-radio-button>
    <el-radio-button label="2">采集配置模板</el-radio-button>
    <el-radio-button label="3">安检配置项</el-radio-button>
    <el-radio-button label="4">安检配置模板</el-radio-button>
  </el-radio-group>
  <PeopleCollec v-if="activeName == '1'"></Peoplecollec>
  <PeopleSample v-if="activeName == '2'"></PeopleSample>
  <PeopleAnJian v-if="activeName == '3'"></PeopleAnJian>
  <PeopleAnJianTemple v-if="activeName == '4'"></PeopleAnJianTemple>
</div>
</template>

<script>
import PeopleCollec from './peopleCollec.vue'
import PeopleSample from './peopleSample.vue'
import PeopleAnJian from './peopleAnjian.vue'
import PeopleAnJianTemple from './peopleAnJianTemple.vue'
export default {
  name: "security",
  components: { PeopleCollec , PeopleSample, PeopleAnJian ,PeopleAnJianTemple},
  data(){
    return{
      activeName:'1'
    }
  },
  mounted() {
    if(this.$route.query.screcd == 1) {
      this.activeName = '1'
    }
    if(this.$route.query.screcd == 2) {
      this.activeName = '2'
    }
    if(this.$route.query.screcd == 4) {
      this.activeName = '4'
    }
  },
}
</script>

<style scoped>

</style>