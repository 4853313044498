<template>
  <div>
    <div class="content">
      <div class="all SearchBar" >
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="用户分类" class="searchItem">
        <el-select v-model="pageParams.userType" placeholder="请选择" size="mini" clearable filterable @change="searchList" >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="使用情况" class="searchItem">
         <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable filterable @change="searchList" >
        <el-option
            v-for="item in useCondition"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="启用状态" class="searchItem">
        <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable filterable  @change="searchList" >
        <el-option
            v-for="item in enableStart"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="模板名称" class="searchItem">
        <el-input
          size="mini"
          style="width: 200px;margin:0 10px 0 8px"
          v-model="pageParams.tplName"
          placeholder="请输入关键字"
          clearable
      ></el-input>
      </el-form-item>
        <el-form-item label="" class="searchItem">
        <el-button type="primary" @click="searchList" size="mini" >查询</el-button>
        <el-button type="warning" @click="searchList('reset')" size="mini" >重置</el-button>
        </el-form-item>
        </el-form>
      </div>
      <el-divider style="color: #f2f2f2"></el-divider>
      <div class="tableTool">
        <span style="float:right;margin:0.5%;margin-right: 16px;">
          <el-button type="text" style="border: 1px solid #409EFF;color: #409EFF;width: 80px;" size="mini" @click="goPath('/home/peopleAddAnjianTemplate')" v-if="$anthButtons.getButtonAuth('myajmbtj')">添加</el-button>
        </span>
      </div>
      <div>
      <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;margin: 15px 0;padding:0"
          border
          :stripe="true"
          height="calc(100vh - 482px)"
          :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}"
      >
        <el-table-column
            type="index"
            label="序号"
            width="60"
        >
        </el-table-column>
        <el-table-column
            label="模板名称"
            prop="tplName"
        >
        </el-table-column>
        <el-table-column
            label="用户分类"
            prop="userTypeName"
        >
        </el-table-column>
        <el-table-column
            label="安检项数量"
            prop="itemsCount"
        >
        </el-table-column>

        <el-table-column
            label="使用情况"
            prop="used"
        >
        </el-table-column>
        <el-table-column
            label="启用状态"
            prop="generalStatus"
        >
          <template slot-scope="{row}">
            <el-tag v-if="row.generalStatus== 0" type="danger">禁用</el-tag>
            <el-tag v-if="row.generalStatus== 1" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            prop="level"
        >
          <template slot-scope="scope">
            <el-button type="primary" @click="goPath('/home/peopleAnjianTemplateDesc',scope.row.id)" size="mini" v-if="$anthButtons.getButtonAuth('myajmbck')">查看</el-button>
            <el-button type="primary" @click="modify(scope.row)" size="mini"  v-if="scope.row.generalStatus == 0&&scope.row.used == '未使用'&& $anthButtons.getButtonAuth('myajmbbj')" >编辑</el-button>
            <el-button type="success" @click="onShowDesign(scope.row)" size="mini" v-if="scope.row.generalStatus == 0 && $anthButtons.getButtonAuth('myajmbqty')" style="background:#3DAF40">启用</el-button>
            <el-button type="danger" @click="onShowDesign(scope.row)" size="mini" v-if="scope.row.generalStatus == 1 && $anthButtons.getButtonAuth('myajmbqty')" style="background:red">停用</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
    >
    </el-pagination>
    </div>
  </div>
</template>

<script>
import {selectDictListByPid} from "../../RequestPort/business/userFile";
import {scuTemplateGeneralStatusToggle, scuTemplatePageList} from "../../RequestPort/peopleUser/people";

export default {
  name: "peopleAnJianTemple",
  data(){
    return{
      pageParams:{
        current:1,
        size: 100,
        userType:'',
        used:'',
        generalStatus:''
      },
      options:[],
      tableData:[],
      total:null,
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ],
    }
  },
  mounted() {
    this.getList()
    selectDictListByPid({pid: 732}).then(res => {
      this.options = res.data
    })
  },
  methods:{
    modify(row){
      this.$router.push({ path: '/home/peopleAddAnjianTemplate', query: { id: row.id, disable: false } })
    },
    onShowDesign(row) {
      scuTemplateGeneralStatusToggle({id:row.id}).then(res => {
        this.$message.success(res.data);
        this.getList()
      })
    },
    searchList(val){
      if(val=='reset'){
        this.pageParams ={
          current:1,
          size: 100,
          content:'',
          entryType:'',
          level:'',
          sorting:0,
        }
      } else {
        this.pageParams.current = 1
      }
      this.getList()
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    getList(){
      scuTemplatePageList(this.pageParams).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  }
}
</script>

<style scoped lang="scss">
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  background-color: #ffffff;
}
.all {
  background-color:#fff;height:auto
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 294px);
}
.search{
  width: 190px;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
</style>