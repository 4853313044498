<template>
  <div>
    <div class="content">
      <div class="all SearchBar" >
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="安检对象" class="searchItem">
        <el-select v-model="pageParams.entryType" placeholder="请选择" size="mini" filterable clearable @change="searchList" >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
        </el-form-item>
        <el-form-item label="隐患等级" class="searchItem">
        <el-select v-model="pageParams.level" placeholder="请选择" size="mini" clearable filterable  @change="searchList" >
        <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="使用情况" class="searchItem">
        <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable filterable @change="searchList" >
        <el-option
            v-for="item in useCondition"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="启用状态" class="searchItem">
        <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable filterable  @change="searchList" >
        <el-option
            v-for="item in enableStart"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
        <el-form-item label="安检内容" class="searchItem">
        <el-input
          size="mini"
          style="width: 200px;margin:0 10px 0 8px"
          v-model="pageParams.content"
          placeholder="请输入关键字"
          clearable
      ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="searchList" size="mini" >查询</el-button>
        <el-button type="warning" @click="searchList('reset')" size="mini" >重置</el-button>
        </el-form-item>
        </el-form>
      </div>
      <el-divider style="color: #f2f2f2"></el-divider>
      <div class="tableTool">
        <span style="float:right;margin:0.5%;margin-right: 16px;">
          <el-button type="text"
                     style="border: 1px solid #409EFF;
    color: #409EFF;
    width: 80px;"
                     size="mini" @click="dialogVisible = true" v-if="$anthButtons.getButtonAuth('myajpztj')">添加</el-button>
        </span>
      </div>
      <div >
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;padding:0"
            border
            :stripe="true"
            height="calc(100vh - 482px)"
            :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}"
        >
          <el-table-column
              type="index"
              label="序号"
              width="60"
          >
          </el-table-column>
          <el-table-column
              label="安检对象"
              prop="entryTypeName"
          >
          </el-table-column>
          <el-table-column
              label="安检内容"
              prop="content"
          >
          </el-table-column>
          <el-table-column
              label="隐患等级"
              prop="level"
          >
          </el-table-column>

          <el-table-column
              label="排序"
              prop="sorting"
          >
            <template slot-scope="{ row }">
              <el-input v-model.number="row.sorting"
                placeholder="请输入排序"
                type="number"
                @input="row.sorting = row.sorting>9999  ? 9999 : (( row.sorting< 0) ? parseInt(Math.abs(row.sorting)): parseInt(row.sorting))"
                @change="sortNumber(row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              label="使用情况"
              prop="used"
          >
          </el-table-column>
          <el-table-column
              label="启用状态"
              prop="generalStatus"
          >
            <template slot-scope="{row}">
              <el-tag v-if="row.generalStatus== 0" type="danger">禁用</el-tag>
              <el-tag v-if="row.generalStatus== 1" type="success">启用</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              prop="level"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="modify(scope.row)" size="mini"  v-if="scope.row.generalStatus == 0&&scope.row.used =='未使用' && $anthButtons.getButtonAuth('myajpzxbj')">编辑</el-button>
              <el-button type="success" @click="onShowDesign(scope.row)" size="mini" v-if="scope.row.generalStatus == 0 && $anthButtons.getButtonAuth('myajpzxqty')" style="background:#3DAF40">启用</el-button>
              <el-button type="danger" @click="onShowDesign(scope.row)" size="mini" v-if="scope.row.generalStatus == 1 && $anthButtons.getButtonAuth('myajpzxqty')" style="background:red">停用</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <el-pagination
          background
          layout="total, prev, pager, next,sizes, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageParams.current"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    <el-dialog
        :title="updataStatus == 0 ? '新增安检项' : '编辑安检项'"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="clearnsonF('form')"
    >
            <el-form ref="form" :model="form" label-width="110px" :rules="rule">
              <el-form-item label="安检对象"  prop="entryType">
                <el-select v-model="form.entryType" placeholder="请选择" clearable class="search" filterable style="width:100%" >
                  <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name+''"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="安检内容"  prop="content">
                <el-input
                    style="width:100%"
                    v-model="form.content"
                    placeholder="请输入安检内容"
                    clearable
                    class="search"
                ></el-input>
              </el-form-item>
              <el-form-item label="隐患等级"  prop="level">
                <el-select v-model="form.level" placeholder="请选择" clearable class="search" filterable style="width:100%">
                  <el-option
                      v-for="item in levelList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="排序"  prop="sorting">
                <el-input
                    type="number"
                    style="width:100%"
                    v-model="form.sorting"
                    placeholder="请输入0~9999，数字越小，排序越靠前"
                    clearable
                ></el-input>
              </el-form-item>
            </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitfrom('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {updateSortNum1} from "../../RequestPort/Login/LoginRequest";
import {selectDictListByPid} from "../../RequestPort/business/userFile";
import {
  scuItemPageList,
  scuItemInsertOrUpdate,
  scuItemGeneralStatusToggle,
} from "../../RequestPort/peopleUser/people";
export default {
  name: "peopleAnjian",
  data(){
    return{
      updataStatus:0,
      dialogVisible:false,
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ],
      // 级别
      levelList:[
        {label:'一级',value:1},
        {label:'二级',value:2},
        {label:'三级',value:3},
        {label:'四级',value:4},
        {label:'五级',value:5},
        {label:'六级',value:6},
        {label:'七级',value:7}
      ],
      options:[],
      tableData:[],
      total:null,
      rule:{
        content: [{ required: true, message: "请输入", trigger: "blur" }],
        entryType: [{ required: true, message: "请选择", trigger: ['change', 'blur'] }],
        level: [{ required: true, message: "请选择", trigger: ['change', 'blur'] }],
        sorting: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      form:{
        content:'',
        entryType:'',
        level:'',
        sorting:0,
      },
      pageParams:{
        current:1,
        size: 100
      }
    }
  },
  mounted() {
    this.getList()
    selectDictListByPid({pid: 737}).then(res => {
      this.options = res.data
    })
  },
  methods:{
    modify(row){
      let obj = JSON.parse(JSON.stringify(row))
      this.form = obj
      this.form.entryType = obj.entryType+''
      this.dialogVisible = true
      this.updataStatus = 1
    },
    onShowDesign(row) {
      scuItemGeneralStatusToggle({id:row.id}).then(res => {
        this.$message.success(res.data);
        this.getList()
      })
    },
    submitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        scuItemInsertOrUpdate(this.form).then(res=>{
          if (res.code === 200) {
            this.$message.success(res.data);
            this.dialogVisible = !this.dialogVisible;
            this.searchList();
          } else {
            this.$message.error(res.data);
          }
        })
      })
    },
    clearnsonF(form){
      this.form = {
        content:'',
        entryType:'',
        level:'',
        sorting:0,
      }
      this.updataStatus = 0
      this.$refs[form].resetFields();
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    searchList(val){
      if(val=='reset'){
        this.pageParams ={
          current:1,
          size: 100,
          content:'',
          entryType:'',
          level:'',
          sorting:0,
        }
      } else {
        this.pageParams.current = 1
      }
      this.getList()
    },
    /**
     * 排序
     */
    sortNumber(row){
      if(!row.sorting){
        row.sorting = 0
      }
      let data ={
        id:row.id,
        field: 'sorting',
        sortNum:row.sorting,
        table:'gas_zhonglian.arum_scu_item'
      }
      updateSortNum1(data).then(e => {
        if (e.code === 200) {
          this.$message.success('修改排序成功');
          this.getList()
        }
      })
    },
    getList(){
      scuItemPageList(this.pageParams).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  background-color: #ffffff;
}
.all {
  background-color:#fff;height:auto
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 294px);
}
.search{
  width: 190px;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
</style>